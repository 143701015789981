import styles from '../ForgotPassword.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm, FormGroup, CaptchaFormGroup, EmailAddressField, useCaptchaOnSubmit } from 'components/objects/forms';
import { submitForgotPassword } from 'behavior/pages/forgotPassword';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { toasts } from 'behavior/toasts';

const ForgotPasswordForm = ({ email, succeeded, navigateTo, unlockForm, submitForgotPassword, className = '' }) => {
  const [successMsgText, emailTitle] = useSanaTexts([
    'SendPassword_Text',
    'EmailAddress',
  ]).texts;

  useEffect(() => {
    if (succeeded) {
      toasts.success(makeRichText(successMsgText), { className: styles.toast });
      navigateTo(routesBuilder.forLogin());
    }

    unlockForm(FormLockKeys.ForgotPassword);
  }, [succeeded]);

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    submitForgotPassword(formData);
  }, FormLockKeys.ForgotPassword, []);

  return (
    <SanaForm
      onSubmit={onSubmit}
      name="ForgotPassword"
      initialValues={{ email: email || '' }}
      focus={email != null}
      className={`${styles.form} ${className}`}
    >
      <FormGroup
        fieldName="email"
        fieldTitle={makeSimpleText(emailTitle)}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
        }}
      />
      <CaptchaFormGroup ref={captchaRef} />
      <SanaForm.SubmitRow />
    </SanaForm>
  );
};

ForgotPasswordForm.propTypes = {
  email: PropTypes.string,
  succeeded: PropTypes.any,
  navigateTo: PropTypes.func.isRequired,
  unlockForm: PropTypes.func.isRequired,
  submitForgotPassword: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default connect(
  state => ({ succeeded: state.page.forgotSucceededAt }),
  { navigateTo, unlockForm, submitForgotPassword },
)(ForgotPasswordForm);
